import React from "react";
import StripeConnectButton from "../StripeConnectButton";
import FeatureContainer from "../../components/FeatureContainer";
import FeatureTitle from "../../components/FeatureContainer/FeatureTitle";
import ExternalLink from "../../components/ExternalLink";

const PaymentsOauth = ({ data }) => {
  return (
    <FeatureContainer>
      <FeatureTitle
        title="Stripe"
        description="Discover the simplicity of text to pay"
      >
        <StripeConnectButton />
      </FeatureTitle>
      <div className="d-flex flex-row feature-container justify-content-center align-items-center">
        <div style={{ maxWidth: "50ch" }}>
          <h5 className="mb-3">THE QUICKEST WAY TO GET PAID</h5>
          <p>
            By connecting your account you will be able to send your customers
            an invoice they can pay via text.
          </p>
          <ul className="ps-3" style={{ maxWidth: "45ch" }}>
            <li>
              Send a secure payment link with a customized invoice and message.
            </li>
            <li className="my-2">
              Easily accept all payment types powered by Stripe and BNPL options
              through Klarna, Affirm, and Afterpay.
            </li>
          </ul>

          <div className="lead">
            <span className="text-left  d-inline">
              {data?.stripe?.application_fee}%
            </span>
            <span className="mx-2">+</span>
            <span>Stripe Fees</span>
          </div>
          <div className="d-flex gap-3 align-items-center mt-4">
            <div
              hidden={!data?.stripe?.connected}
              className="alert alert-success"
            >
              <i className="bi-check-circle me-1" />
              Your account is connected.
            </div>
            <StripeConnectButton
              treatment={data?.stripe?.connected && "link"}
            />
            <ExternalLink href="https://www.ikeono.com/pricing/?utm_medium=payments&utm_source=webapp&utm_term=prospecting">
              Learn more
            </ExternalLink>
          </div>
        </div>
        <div className="d-flex flex-column">
          <img src="https://images.squarespace-cdn.com/content/v1/63a3448b4bb6213ae43afb6d/ccf1c298-d3f5-4721-99ae-89590cd71261/Payments+-+buy+now+pay+later+apple+google+pay.png?format=1500w" />
        </div>
      </div>
    </FeatureContainer>
  );
};

export default PaymentsOauth;
