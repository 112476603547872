import React from "react";
import { Route, Routes } from "react-router-dom";
import LightspeedStoreAssignment from "../../components/Lightspeed/LightspeedStoreAssignment";
import LightspeedSpecialOrder from "../../components/Lightspeed/LightspeedSpecialOrder";
import LightspeedWorkorderAutomation from "../../components/Lightspeed/LightspeedWorkorderAutomation/LightspeedWorkorderAutomation";
import LightspeedConnectionCheck from "../../components/Lightspeed/LightspeedConnectionCheck";

const LocationLightspeedSettings = () => {
  return (
    <LightspeedConnectionCheck>
      <Routes>
        <Route
          path="store-assignment"
          element={<LightspeedStoreAssignment />}
        />
        <Route
          path="special-order-message"
          element={<LightspeedSpecialOrder />}
        />
        <Route
          path="workorder-automation/*"
          element={<LightspeedWorkorderAutomation />}
        />
      </Routes>
    </LightspeedConnectionCheck>
  );
};

export default LocationLightspeedSettings;
