import React from "react";
import * as api from "../../lib/api";
import SimpleForm from "../Form/SimpleForm";
import MergeFieldsTextArea from "../Form/MergeFieldsTextArea";
import FeaturePermissionCheck from "../FeaturePermissionCheck";
import { CommonMergeFields, MergeField } from "../Form/MergeFields";
import { PricingPlans } from "../UpgradeAccountButtonV2";
import Checkbox from "../Form/Checkbox";
import FeatureContainer from "../FeatureContainer";
import FeatureTitle from "../FeatureContainer/FeatureTitle";
import { FeatureTierBadge } from "../FeatureTierBadge";

const mergeFields = [
  CommonMergeFields.firstName,
  CommonMergeFields.lastName,
  new MergeField(
    "Item Title",
    "{title}",
    "The item name of the special order."
  ),
];

const LightspeedSpecialOrder = () => {
  return (
    <FeatureContainer>
      <FeatureTitle
        title={
          <div className="text-capitalize d-flex align-items-center">
            <span>Special Order Notifications</span>
            <FeatureTierBadge className="ms-3 " />
          </div>
        }
        description="Text special order notifications without leaving Lightspeed"
      />
      <div className="feature-container">
        <FeaturePermissionCheck
          permissionKey="special_order_module"
          plan={PricingPlans.standard}
          redirectPath="/settings/location/lightspeed-r-series/special-order-message"
        >
          <div className="d-flex gap-5 justify-content-center align-items-center">
            <div style={{ width: "50ch" }}>
              <SimpleForm
                id="specialOrderForm"
                apiGetHandler={async () => {
                  const response = await api.get("/shop/{exid}/specialorder/");
                  return response;
                }}
                apiSubmitHandler={(values) =>
                  api.post("/shop/{exid}/specialorder/", values)
                }
              >
                <MergeFieldsTextArea
                  id="message"
                  name="message"
                  as="textarea"
                  mergeFields={mergeFields}
                />
                <div className="mt-3 mb-4">
                  <Checkbox id="enabled" label="Enabled">
                    Enabled
                  </Checkbox>
                </div>
              </SimpleForm>
            </div>

            <img
              alt="Feature "
              height="155px"
              className="rounded shadow"
              src="https://portal.ikeono.com/img/ikeono-special-order-feature.png"
            />
          </div>
        </FeaturePermissionCheck>
      </div>
    </FeatureContainer>
  );
};

export default LightspeedSpecialOrder;
