import React from "react";
import SimpleForm from "../../components/Form/SimpleForm";
import { SettingsFactory } from "../../components/Settings/SettingsFactory";
import { put } from "../../lib/api";
import LightspeedPaymentsSettingsDisconnectedWarning from "../LightspeedPaymentsSettingsDisconnectedWarning";
import LightspeedConnectionCheck from "../../components/Lightspeed/LightspeedConnectionCheck";
import FeatureContainer from "../../components/FeatureContainer";
import FeatureTitle from "../../components/FeatureContainer/FeatureTitle";

const makeFields = (registers, paymentTypes, employees) => {
  return [
    {
      label: "Add Deposits",
      body: "Add a deposit to the Customer profile in Lightspeed R-Series.",
      as: "checkbox",
      props: {
        id: "add_deposit_to_workorders",
        name: "add_deposit_to_workorders",
        label: "Add customer deposit",
      },
    },
    {
      label: "Lightspeed Register",
      body: "The deposit transaction will be associted with this register.",
      as: "select",
      props: {
        id: "pos_register_id",
        name: "pos_register_id",
        values: registers,
      },
    },
    {
      label: "Lightspeed Payment Type",
      body: "The payment type to associate with the deposit transaction.",
      as: "select",
      props: {
        id: "pos_payment_source_id",
        name: "pos_payment_source_id",
        values: paymentTypes,
      },
    },
    {
      label: "Lightspeed Employee",
      body: (
        <div>
          <span className="badge text-bg-info text-white">New Parameter</span>{" "}
          This is a newly required field that Lightspeed requires for adding a
          customer deposit. It is mostly used for reporting.
        </div>
      ),
      as: "select",
      props: {
        id: "pos_employee_id",
        name: "pos_employee_id",
        values: employees,
      },
    },
  ];
};

const PaymentsLightspeedRSeriesDepositForm = ({
  settings,
  registers,
  paymentTypes,
  posDataError,
  employees,
}) => {
  const [fields] = React.useState(
    makeFields(registers, paymentTypes, employees)
  );

  const apiSubmitHandler = async (values) => {
    if (values.redirect_url && !values.redirect_url?.includes("http")) {
      values.redirect_url = `https://${values.redirect_url}`;
    }

    if (values.add_deposit_to_workorders) {
      if (
        !values.pos_employee_id ||
        !values.pos_payment_source_id ||
        !values.pos_register_id
      ) {
        const error = Error(
          "Lightspeed Register, Lightspeed Payment Type, Lightspeed Employee are required fields when you have deposits enabled."
        );
        error.source = "ik";
        throw error;
      }
    }

    await put("/shop/{exid}/invoice/settings", values);
  };

  return (
    <FeatureContainer>
      <FeatureTitle
        title="Lightspeed R-Series Deposit Settings"
        description="Add a deposit to a customer's profile when an invoice is paid."
      />

      <LightspeedConnectionCheck>
        <LightspeedPaymentsSettingsDisconnectedWarning
          message={posDataError}
          hidden={!posDataError}
        />
        <div className="feature-container" hidden={posDataError}>
          <SimpleForm
            initialValues={settings}
            apiSubmitHandler={apiSubmitHandler}
          >
            <fieldset disabled={!!posDataError}>
              <SettingsFactory fields={fields} />
            </fieldset>
          </SimpleForm>
        </div>
      </LightspeedConnectionCheck>
    </FeatureContainer>
  );
};

export default PaymentsLightspeedRSeriesDepositForm;
