import React from "react";

const FeatureTitle = ({ title, description, children }) => {
  return (
    <div className="my-2 my-md-4 d-flex flex-column flex-md-row align-items-center">
      <div>
        <h5 className="fw-bold">{title}</h5>
        <span>{description}</span>
      </div>
      <div className="ms-auto">{children}</div>
    </div>
  );
};

export default FeatureTitle;
