import React from "react";

const DataPoint = ({ title, subtitle, value, icon }) => {
  return (
    <div className="d-flex gap-2 ">
      <i className={icon} />
      <div className="d-flex flex-column">
        <span className="fs-15 fw-bold text-nowrap">{title}</span>
        <span className="fs-14">{subtitle}</span>
      </div>
      <div className="ms-auto h5 fw-bold align-self-center">{value}</div>
    </div>
  );
};

const DataPointsList = ({ children }) => {
  return (
    <div
      className="h-100 bg-white d-flex flex-column p-3 shadow-sm"
      style={{ minWidth: "400px" }}
    >
      {children?.map((dataPoint, idx) => {
        return (
          <>
            <DataPoint {...dataPoint} />
            <hr hidden={idx === children.length - 1} />
          </>
        );
      })}
    </div>
  );
};

export default DataPointsList;
