import React from "react";
import { ListItem } from "../../FeatureUpgrade/SplitPanelFeatureUpgrade";
import UpgradeAccountButtonV2, {
  PricingPlans,
} from "../../components/UpgradeAccountButtonV2";

const WebchatPremiumFeatures = () => {
  return (
    <div className="d-flex gap-3 flex-column lead fw-medium">
      <p className="h4">Upgrade to Premium</p>
      <ListItem>Everything in standard</ListItem>
      <ListItem>Remove Ikeono branding.</ListItem>
      <ListItem>Change the greeter message.</ListItem>
      <ListItem>Change the greeter image.</ListItem>
      <ListItem>Show your Google Reviews score.</ListItem>
      <ListItem>
        Track webchat events in your Google Analytics dashboard.
      </ListItem>

      <div className="align-self-center mt-3">
        <UpgradeAccountButtonV2
          featureName="Upgrade to Webchat Premium"
          plan={PricingPlans.premium}
          redirectPath="/settings/location/webchat-widget"
        />
      </div>
    </div>
  );
};

export default WebchatPremiumFeatures;
