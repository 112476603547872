import React from "react";
import { useNavigate } from "react-router-dom";
import { useState } from "../lib/overmind";
import { Tooltip } from "../components/Tooltip";
import CreateButton from "../components/CreateButton";

const ContactPageHeader = () => {
  const navigate = useNavigate();
  const state = useState().contacts;

  return (
    <div className="text-end">
      <Tooltip
        disabled={state.canCreate}
        title="Please go to your Point of Sale to create a new contact."
        placement="left"
      >
        <CreateButton
          disabled={!state.canCreate || state.isLoading}
          onClick={() => navigate("/contacts/create")}
        >
          Create Contact
        </CreateButton>
      </Tooltip>
    </div>
  );
};

export default ContactPageHeader;
