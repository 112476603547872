/* eslint-disable no-undef */
import React from "react";
import OvermindDataTable from "../../DataTable/OvermindDataTable";
import LightspeedWorkorderZeroState from "./LightspeedWorkorderZeroState";
import LightspeedCreateWorkorderAutomationModal from "./LightspeedCreateWorkorderAutomationModal";
import LightspeedEditWorkorderAutomationModal, {
  editWorkorderOffcanvasId,
} from "./LightspeedEditWorkorderAutomationModal";
import FeaturePermissionCheck from "../../FeaturePermissionCheck";
import { PricingPlans } from "../../UpgradeAccountButtonV2";
import LightspeedWorkorderAutomationFaq from "./LightspeedWorkorderAutomationFaq";
import LightspeedWorkorderAutomationTitle from "./LightspeedWorkorderAutomationTitle";
import { useState } from "../../../lib/overmind";
import { useModalDisplayManager } from "../../ModalHook";

const LightspeedWorkorderAutomation = () => {
  const state = useState().lightspeed;
  useModalDisplayManager(editWorkorderOffcanvasId, [
    "/workorder-automation/{int}/edit",
  ]);

  return (
    <div>
      <LightspeedWorkorderAutomationTitle isLoading={state.isLoading} />
      <FeaturePermissionCheck
        permissionKey="workorder_status_automation"
        plan={PricingPlans.standard}
        redirectPath="/settings/location/lightspeed-r-series/workorder-automation"
      >
        <div className="d-flex gap-3">
          <OvermindDataTable
            overmindPropertyKey="lightspeed"
            ZeroStateComponent={<LightspeedWorkorderZeroState />}
          />
          <LightspeedWorkorderAutomationFaq />
        </div>
        <LightspeedCreateWorkorderAutomationModal />
        <LightspeedEditWorkorderAutomationModal />
      </FeaturePermissionCheck>
    </div>
  );
};

export default LightspeedWorkorderAutomation;
