/* eslint-disable no-shadow */
import React, { useEffect, useState } from "react";
import { Routes, useParams, Route, useNavigate } from "react-router-dom";
import { json } from "overmind";
import ContactDetail from "./ContactDetail";
import OffCanvas from "../components/OffCanvas";
import * as overmind from "../lib/overmind";
import ContactForm from "./ContactForm";
import NavigateBackLink from "../components/NavigateBackLink";
import CreateContactForm from "./CreateContactForm";
import { useModalDisplayManager } from "../components/ModalHook";

const contactDetailOffCanvasId = "contact-detail-offcanvas";

const ContactDetailOffCanvas = () => {
  const params = useParams();
  const id = params["*"];
  const actions = overmind.useActions().contacts;
  const state = overmind.useState().contacts;
  const [contact, setContact] = useState();
  const navigate = useNavigate();
  useModalDisplayManager(
    contactDetailOffCanvasId,
    [
      "/contacts/{uid}",
      "/contacts/{uid}/edit",
      "/contacts/{int}", // {int} is used for point of sale systems like LS
      "/contacts/{int}/edit",
      "/contacts/create",
    ],
    true
  );

  useEffect(() => {
    if (id?.includes("edit")) return; // TODO: Fix this; bad bad
    if (!id || state.isLoading) return;
    const contact = actions.getDetail(id);
    setContact(contact);
  }, [id, state.isLoading]);

  return (
    <OffCanvas
      datatarget={contactDetailOffCanvasId}
      onHide={() => navigate("/contacts", { replace: true })}
    >
      <Routes>
        <Route
          path="create"
          element={
            <CreateContactForm
              onSuccess={({ id }) =>
                navigate(`/contacts/${id}`, { replace: true })
              }
            />
          }
        />
        <Route
          path=":id"
          element={
            <ContactDetail
              firstName={contact?.first_name}
              lastName={contact?.last_name}
              phoneNumber={contact?.mobile_phone_number}
              email={contact?.email}
              dateCreated={contact?.date_created}
              vendorUrl={contact?.vendor_url}
              vendorId={contact?.vendor_id}
              vendorSource={contact?.vendor_source}
              isLoading={state.isLoading}
            />
          }
        />
        <Route
          path=":id/edit"
          element={
            <div className="d-flex flex-column">
              <h5>Edit Contact</h5>
              <NavigateBackLink style={{ position: "absolute", top: "5px" }} />
              <ContactForm
                onSuccess={() => {
                  navigate(-1);
                }}
                contact={json(contact)}
                onDeleteSuccess={() => {
                  navigate("/contacts");
                }}
              />
            </div>
          }
        />
      </Routes>
    </OffCanvas>
  );
};

export default ContactDetailOffCanvas;
