import { reportError } from "../../errorHandler";
import { formatMinutes } from "../../date";

const buildDataTable = (data) => {
  const indexedStatuses = {};
  data.workorder_status?.map((s) => {
    indexedStatuses[s.id] = s;
    return null;
  });

  const rows = data.campaigns?.map((d) => ({
    id: d.id,
    url: `${d.id}/edit`,
    data_bs_target: "#editWorkorderModal",
    data_bs_toggle: "modal",
    columns: [
      {
        value: indexedStatuses[d.status]?.name,
        ui_background_color: indexedStatuses[d.status]?.html_color || "#6c757d",
        type: "badge",
      },
      {
        value: formatMinutes(d.min_delay) || "ASAP",
      },
      {
        value: d.enabled,
        type: "bool",
      },
      {
        value: d.body,
        className: "text-none",
      },
    ],
  }));
  return {
    headers: ["Status", "Delay", "Active", "Body"],
    rows,
  };
};

export const get = async ({ state, effects }) => {
  if (state.lightspeed.isLoading) return;

  state.lightspeed.isLoading = true;
  state.lightspeed.responseError = null;
  state.lightspeed.configs = null;
  if (state.lightspeed.tableData) {
    /*
    TODO: Maybe there needs to be one endpoint that loads the table configuration (e.g. filters) and another to fetch the data.
    Otherwise we have to maintain the last successful response so that the filter can still show.  This allows the user to
    either change the filter, reset the filter or rerun the report
    */
    state.lightspeed.tableData.rows = null;
  }

  try {
    const apiData = await effects.lightspeed.getWorkorderStatuses(
      state.payments.filterParams
    );
    state.lightspeed.tableData = buildDataTable(apiData);
    state.lightspeed.apiData = apiData;
    state.lightspeed.configs = apiData.campaigns;
  } catch (error) {
    reportError(error);
    state.lightspeed.errorMessage = "There was an error";
    state.lightspeed.responseError = error.response;
  }
  state.lightspeed.isLoading = false;
};

export const updateFilter = () => {};

export const deleteWorkorderConfig = (
  { state, effects },
  workorderConfigId
) => {
  state.lightspeed.apiData.campaigns =
    state.lightspeed.apiData.campaigns.filter(
      (c) => c.id !== workorderConfigId
    );
  state.lightspeed.tableData = buildDataTable(state.lightspeed.apiData);
  effects.lightspeed.delete_(workorderConfigId);
};

export const updateOrAddWorkorderConfig = ({ state }, workorderConfig) => {
  const idx = state.lightspeed.apiData.campaigns?.findIndex(
    (c) => c.id === workorderConfig.id
  );
  if (idx < 0) {
    if (!state.lightspeed.apiData.campaigns?.length) {
      state.lightspeed.apiData.campaigns = [workorderConfig];
    } else {
      state.lightspeed.apiData.campaigns.push(workorderConfig);
    }
  } else {
    state.lightspeed.apiData.campaigns[idx] = workorderConfig;
  }
  state.lightspeed.tableData = buildDataTable(state.lightspeed.apiData);
};

export const getWorkorder = ({ state }, workorderMessageId) => {
  return state.lightspeed.apiData?.campaigns.find(({ id }) => {
    return id.toString() === workorderMessageId;
  });
};

export const getWorkorderStatus = async ({ state, effects }) => {
  state.lightspeed.errorMessage = null;
  state.lightspeed.responseError = null;
  state.lightspeed.isLoading = true;

  try {
    const apiData = await effects.lightspeed.getWorkorderStatuses();
    state.lightspeed.apiData = apiData;
  } catch (error) {
    reportError(error);
    state.lightspeed.errorMessage = "There was an error";
    state.lightspeed.responseError = error.response;
  }
  state.lightspeed.isLoading = false;
};
