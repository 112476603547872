import React from "react";
import LightspeedWorkorderAutomationStripeWarning from "./LightspeedWorkorderAutomationStripeWarning";

const LightspeedWorkorderAutomationFaq = () => {
  return (
    <div className="d-flex" style={{ maxWidth: "30ch" }}>
      <div>
        <LightspeedWorkorderAutomationStripeWarning />
        <div className="card mt-2" style={{ minWidth: "25ch" }}>
          <div className="card-body d-flex flex-column gap-2">
            <b>Things to know</b>
            <span>
              <i className="bi-check me-1" />
              The workorder must remain in the status for at least 15 minutes.
            </span>
            <span>
              <i className="bi-check me-1" />
              Changing the status will reset the timer.
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LightspeedWorkorderAutomationFaq;
