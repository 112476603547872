import React from "react";

import {
  BrowserExtensionAppIcon,
  EcwidAppIcon,
  GoogleAppIcon,
  LightspeedRSeriesAppIcon,
  LightspeedXSeriesAppIcon,
  MessengerAppIcon,
  StripeAppIcon,
  WebchatAppIcon,
} from "../IntegrationAppIcon";

const index = {
  stripe: {
    icon: <StripeAppIcon />,
    title: "Payments",
    body: "Send a secure payment link to customers to capture payments on invoices, quotes, or work orders. ",
    url: "/settings/location/payments/connection",
  },
  google: {
    icon: <GoogleAppIcon />,
    title: "Google",
    body: "Setup Google Business Profile to setup your reviews, sync your store hours and more.",
    url: "/settings/location/google/oauth",
  },
  lightspeed_r_series: {
    icon: <LightspeedRSeriesAppIcon />,
    title: "Lightspeed R-Series",
    body: "Send messages, invoice links, automated messages and more when you connect Lightspeed.",
    url: "/settings/location/lightspeed-r-series/store-assignment",
  },
  lightspeed_x_series: {
    icon: <LightspeedXSeriesAppIcon />,
    title: "Lightspeed X-Series",
    body: "Send messages, invoice links, and more when you connect Vend.",
    url: "/settings/location/lightspeed-x-series/store-assignment",
  },
  browser_extension: {
    icon: <BrowserExtensionAppIcon />,
    title: "Browser Extension",
    body: "The Ikeono Browser extension adds functionality to your Point of Sale system.",
    url: "/settings/location/browser-extension",
    status: null,
  },
  webchat_widget: {
    icon: <WebchatAppIcon />,
    title: "Webchat Widget",
    body: "Capture leads while they are on your website with an easy-to-install button that converts leads into customers.",
    url: "/settings/location/webchat-widget",
  },
  messenger: {
    icon: <MessengerAppIcon />,
    title: "Facebook Messenger",
    body: "View and reply to Facebook Messenger conversations from within Ikeono.",
    url: "/settings/location/meta/messenger",
  },
  ecwid: {
    icon: <EcwidAppIcon />,
    title: "Ecwid",
    body: "Add Stripe as a payment option to your Ecwid Store",
    url: "/settings/location/ecwid",
  },
};

export default index;
