/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable no-unused-vars */
import React, { useState } from "react";
import * as api from "../../lib/api";
import LabeledField from "../Form/LabeledField";
import SimpleForm from "../Form/SimpleForm";
import InfoBubble from "../../lib/InfoBubble";
import StoreAssignmentField from "../Form/StoreAssignmentField";
import FeatureContainer from "../FeatureContainer";
import FeatureTitle from "../FeatureContainer/FeatureTitle";

const makeFieldName = (storeId) => `ik_store_id__${storeId}`;

const Row = ({ ikeonoStore, lightspeedStores }) => {
  return (
    <>
      <label
        className="d-flex align-items-center"
        htmlFor={makeFieldName(ikeonoStore.id)}
      >
        <h6 className="mb-0">
          <i className="bi-building me-1 fs-14" />
          {ikeonoStore.name}
        </h6>
      </label>
      <LabeledField
        className="form-select form-select-sm w-auto"
        as="select"
        id={makeFieldName(ikeonoStore.id)}
        name={makeFieldName(ikeonoStore.id)}
        data-ik-store-id={ikeonoStore.id}
      >
        <option />
        {lightspeedStores?.map(({ id, name }) => {
          return (
            <option key={id} value={id}>
              {name}
            </option>
          );
        })}
      </LabeledField>
    </>
  );
};

const LightspeedStoreAssignment = ({ onSubmit }) => {
  return (
    <FeatureContainer>
      <FeatureTitle
        title="Store Association"
        description={
          <div style={{ maxWidth: "80ch" }}>
            Assign your Lightspeed Store to your Ikeono Store to enable
            automations.
          </div>
        }
      />
      <div className="feature-container">
        <SimpleForm
          submitButtonProps={{ children: "Save" }}
          apiGetHandler={async () => {
            const response = await api.get("/shop/{exid}/pos/store");
            const data = {};
            response.data.ikeono_stores.forEach((store) => {
              data[makeFieldName(store.id)] = store.pos_store_id || "";
            });
            return {
              data,
              ikeonoStores: response.data.ikeono_stores,
              posStores: response.data.stores,
            };
          }}
          apiSubmitHandler={async (values) => {
            await api.put("/shop/{exid}/pos/store/bulk-update", values);
            onSubmit && onSubmit();
          }}
        >
          {(responseData) => (
            <StoreAssignmentField
              ikeonoStores={responseData?.ikeonoStores}
              externalStores={responseData?.posStores}
            />
          )}
        </SimpleForm>
      </div>
    </FeatureContainer>
  );
};

export default LightspeedStoreAssignment;
