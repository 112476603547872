import React, { useRef } from "react";
import OvermindDataTable from "../components/DataTable/OvermindDataTable";
import OffCanvas, {
  hideOffcanvas,
  toggleOffcanvas,
} from "../components/OffCanvas";
import { useActions, useState } from "../lib/overmind";
import Button, { ButtonVarint } from "../components/Button";
import TemplateForm from "./TemplateForm";
import OffcanvasFooter from "../components/OffCanvas/OffcanvasFooter";
import { getShopExternalId } from "../lib/auth";
import FeatureContainer from "../components/FeatureContainer";
import FeatureTitle from "../components/FeatureContainer/FeatureTitle";
import CreateButton from "../components/CreateButton";

const createOffcanvasId = "createTemplatesOffcanvas";
const editOffcanvasId = "editTemplatesOffcanvas";

const TemplatesSettings = ({ shopId }) => {
  const actions = useActions().templates;
  const state = useState().templates;
  const cancelButtonRef = useRef();
  const createCancelButtonRef = useRef();

  return (
    <FeatureContainer>
      <FeatureTitle
        title="Message Templates"
        description="A great way to quickly respond to frequently asked questions or standardize customer interactions."
      >
        <CreateButton onClick={() => toggleOffcanvas(createOffcanvasId)}>
          Create New Templates
        </CreateButton>
      </FeatureTitle>
      <div className="card">
        <OvermindDataTable
          overmindPropertyKey="templates"
          additionalQueryParams={{
            shopId: getShopExternalId(),
          }}
          rowClickHandler={(rowData) => {
            actions.setSelectedEditRecordId(rowData?.id);
            toggleOffcanvas(editOffcanvasId);
          }}
        />
        <OffCanvas datatarget={createOffcanvasId} title="Create Template">
          <TemplateForm
            template={{ key: "", value: "" }}
            onSuccess={() => {
              createCancelButtonRef.current?.click();
            }}
          />
          <OffcanvasFooter>
            <Button
              ref={createCancelButtonRef}
              variant={ButtonVarint.secondaryOutline}
              data-bs-dismiss="offcanvas"
            >
              Cancel
            </Button>
          </OffcanvasFooter>
        </OffCanvas>
        <OffCanvas datatarget={editOffcanvasId} title="Edit Template">
          <TemplateForm
            template={state.selectedEditRecord}
            onSuccess={() => {
              cancelButtonRef.current?.click();
            }}
          />
          <OffcanvasFooter>
            <Button
              ref={cancelButtonRef}
              variant={ButtonVarint.secondaryOutline}
              data-bs-dismiss="offcanvas"
            >
              Cancel
            </Button>
            <Button
              className="ms-auto"
              variant={ButtonVarint.dangerOutline}
              onClick={() => {
                if (!confirm("Are you sure?")) return;
                actions.deleteSelectedEditRecord();
                cancelButtonRef.current?.click(); // Work around. For some reason toggling the canvas does not work
                hideOffcanvas(editOffcanvasId);
              }}
            >
              Delete
            </Button>
          </OffcanvasFooter>
        </OffCanvas>
      </div>
    </FeatureContainer>
  );
};

export default TemplatesSettings;
