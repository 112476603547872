import React from "react";
import { Link } from "react-router-dom";

const PlusIcon = () => <i className="bi-plus-lg me-2" />;

const CreateButton = (props) => {
  if (props?.as === "link") {
    return (
      <Link className="btn btn-primary text-nowrap px-3" {...props}>
        <PlusIcon />
        {props.children}
      </Link>
    );
  }

  return (
    <button
      type="button"
      className="btn btn-primary text-nowrap px-3"
      {...props}
    >
      <PlusIcon />
      {props.children}
    </button>
  );
};

export default CreateButton;
