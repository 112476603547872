import React from "react";
import { Link } from "react-router-dom";
import { useState } from "../../../lib/overmind";

const LightspeedWorkorderAutomationStripeWarning = () => {
  const state = useState().account;

  if (state.data.config.portal.payments.is_stripe_connected) {
    return (
      <div className="card">
        <div className="card-body">
          <b>Payment Links</b>
          <p>
            You can attach a payment link to a Workorder Status message. To get
            started, create a new automation or edit an existing one and then
            click they Payment Link button.
          </p>
        </div>
      </div>
    );
  }

  return (
    <div className="card">
      <div className="card-body">
        <b>Payment Links</b>
        <p>
          You can attach a payment link to a Workorder Status message! But first
          you need to connect Stripe.
        </p>
        <Link
          className="btn btn-outline-primary"
          to="/settings/location/payments/connection"
        >
          Get started
        </Link>
      </div>
    </div>
  );
};

export default LightspeedWorkorderAutomationStripeWarning;
