import React from "react";
import LightspeedWorkorderForm from "./LightspeedWorkorderForm";
import Modal, { hideModal } from "../../Modal";
import Button, { ButtonVarint } from "../../Button";

export const createWorkorderOffcanvasId = "createWorkorderOffcanvas";

const LightspeedCreateWorkorderAutomationModal = () => {
  return (
    <Modal id={createWorkorderOffcanvasId} title="Create new status message">
      <LightspeedWorkorderForm
        onSuccess={() => {
          hideModal(createWorkorderOffcanvasId);
        }}
      >
        <div className="d-flex flex-row">
          <Button className="me-auto" form="lightspeedWorkorderForm">
            Save
          </Button>
          <Button
            variant={ButtonVarint.secondaryOutline}
            onClick={() => {
              hideModal(createWorkorderOffcanvasId);
            }}
          >
            Cancel
          </Button>
        </div>
      </LightspeedWorkorderForm>
    </Modal>
  );
};

export default LightspeedCreateWorkorderAutomationModal;
