import React, { useEffect } from "react";
import DataTable from "./index";
import { useActions, useState } from "../../lib/overmind";
import Loader from "../Loader";
import DataTableError from "./DataTableError";
import APIResponseError from "../APIResponseError";
import FilterBar2 from "./FilterBar2";

/*
Overmind Interface

actions.get
actions.updateFilter

state.errorMessage
state.isLoading
state.tableData
state.tableData.filter
state.tableData.rows
state.tableData.headers
*/

const OvermindDataTable = ({
  overmindPropertyKey,
  rowClickHandler,
  additionalQueryParams,
  ZeroStateComponent,
}) => {
  const actions = useActions();
  const state = useState();
  const targetActions = actions[overmindPropertyKey];
  const targetState = state[overmindPropertyKey];

  useEffect(() => {
    targetActions.get(additionalQueryParams);
  }, []);

  const { isLoading } = targetState;
  const data = targetState.tableData;

  if (targetState.apiError) {
    return <APIResponseError response={targetState.apiError} />;
  }

  return (
    <div className="bg-white rounded-2 p-4 shadow-sm w-100">
      <FilterBar2
        onChange={(newFilter) => {
          targetActions.updateFilter({ ...newFilter, executeFetch: true });
        }}
        controls={data?.filter}
      />
      <DataTableError
        responseError={targetState.responseError}
        onClick={targetActions.get}
      />
      <Loader hidden={!isLoading} />
      <DataTable
        hidden={isLoading}
        rows={data?.rows}
        headers={data?.headers}
        rowClickHandler={rowClickHandler}
        ZeroStateComponent={ZeroStateComponent}
      />
    </div>
  );
};

export default OvermindDataTable;
