/* eslint-disable */

import React, { useEffect } from "react";
import OffCanvas, { hideOffcanvas } from "../components/OffCanvas";
import { Link, useNavigate, useParams } from "react-router-dom";
import ExternalLink from "../components/ExternalLink";
import { useActions } from "../lib/overmind";
import InfoBubble from "../lib/InfoBubble";
import PaymentStatusBadge from "./PaymentStatusBadge";
import { formatCurrency } from "../lib/currency";
import PosVisibility from "../components/PosVisibility";
import PaymentDetailLightspeedDepositStatus from "./PaymentDetailLightspeedDepositStatus";
import { formatDate } from "../lib/date";
import { formatPhoneNumberWithAccountCountry } from "../lib/overmind/account/util";
import DetailTable, { TableRow } from "../components/DetailTable";
import ChangePaymentStatus from "./ChangePaymentStatus";
import { get } from "../lib/api";
import APIResponseError from "../components/APIResponseError";
import Button from "../components/Button";

const PaymentDetail = () => {
  let params = useParams();
  const id = params["*"];
  const actions = useActions().payments;
  const [detail, setDetail] = React.useState();
  const [isLoading, setIsLoading] = React.useState();
  const [error, setError] = React.useState();
  const navigate = useNavigate();
  /*
  Not sure why, but need to store the status separately to rerender appropriately if the user
  voids the payments. Rushing to push this feature out.
  */
  const [status, setStatus] = React.useState();

  useEffect(() => {
    const detail = actions.getDetail(id);
    setDetail(detail);
    setStatus(detail?.status);
  }, [params]);

  let userName = "N/A";
  if (detail?.user_first_name || detail?.user_last_name) {
    userName = `${detail?.user_first_name} ${detail?.user_last_name}`;
  } else if (detail?.user_email) {
    userName = detail?.user_email;
  }

  const onClick = async () => {
    setError(null);
    setIsLoading(true);
    try {
      await get("/shop/{exid}/invoice/resend", {
        invoice_id: detail.invoice_id,
      });
      hideOffcanvas("payment-detail-offcanvas");
      actions.get();
      navigate("/payments");
    } catch (error) {
      setError(error);
    }
    setIsLoading(false);
  };

  return (
    <OffCanvas datatarget="payment-detail-offcanvas" title="Payment Detail">
      <DetailTable>
        <TableRow title="Customer Name" className="text-capitalize">
          {detail?.customer_name || "N/A"}
        </TableRow>
        <TableRow title="Customer Phone" className="text-capitalize">
          {detail?.customer_phone
            ? formatPhoneNumberWithAccountCountry(detail?.customer_phone)
            : "N/A"}
        </TableRow>
        <TableRow title="Created On">
          {formatDate(detail?.date_created)}
        </TableRow>
        <TableRow title="Amount">
          {formatCurrency(detail?.total / 100 || 0, detail?.currency)}
        </TableRow>
        <TableRow title="Description">{detail?.line_item_description}</TableRow>{" "}
        <TableRow title="User">
          <Link
            hidden={!detail?.user_id}
            to={`/settings/users/${detail?.user_id}`}
          >
            {userName}
          </Link>
          <span hidden={detail?.user_id}>N/A</span>
        </TableRow>
        <TableRow title="Invoice Link">
          <ExternalLink href={detail?.hosted_page_url}>
            View Invoice
          </ExternalLink>
        </TableRow>
        <TableRow title="Status">
          <PaymentStatusBadge>{status}</PaymentStatusBadge>
          <ChangePaymentStatus
            hidden={status !== "open"}
            invoiceId={id}
            onSuccess={(newStatus) => {
              setDetail(actions.getDetail(id));
              setStatus(newStatus);
            }}
          />
          <InfoBubble className="mt-2" hidden={status != "void"}>
            <div>
              Stripe automatically voids any invoice that has not been paid
              within 24 hours. This is a security measure dictated by Stripe
              Secure Checkout. You can optionally resend the invoice.
            </div>

            <Button
              className="btn btn-primary btn-sm px-2 my-2"
              onClick={onClick}
              showLoader={isLoading}
            >
              Resend Invoice
            </Button>
            <APIResponseError response={error} />
          </InfoBubble>
        </TableRow>
      </DetailTable>
      <PosVisibility showLightspeed>
        <PaymentDetailLightspeedDepositStatus
          status={detail?.deposit_metadata?.status}
          posUrl={detail?.deposit_metadata?.pos_url}
          errorMessage={detail?.deposit_metadata?.error_message}
          posTransactionId={detail?.deposit_metadata?.pos_transaction_id}
          posPrimaryType={detail?.deposit_metadata?.pos_primary_type}
        />
      </PosVisibility>
    </OffCanvas>
  );
};

export default PaymentDetail;
