import React from "react";
import { Link, useNavigate } from "react-router-dom";
import { formatCurrency } from "../../lib/currency";
import { formatDate, formatSeconds } from "../../lib/date";
import { formatPhoneNumberWithAccountCountry } from "../../lib/overmind/account/util";

/*
    {
      columns: [
        {
           "value": "v1",
           "tag": { "variant": "primary"},
        },
        {
           "value": "v1",
           "link": "/payments/p1",
         },
      ],
      "id": 1,
      "link": "/p1",
    },
*/

const ColumnFormatter = ({ children }) => {
  let v = children.value;
  let rowClassName = children.className || "text-capitalize";

  if (children.hidden) return null;

  if (children.type === "date") {
    v = formatDate(v);
    rowClassName = "text-nowrap";
  } else if (children.type === "currency") {
    v = formatCurrency(v, children.currency || "usd");
  } else if (children.type === "link") {
    if (children.is_internal_url) {
      v = <Link to={v.url || children.url}>{v}</Link>;
    } else {
      v = (
        <a target="_blank" href={children.url} rel="noreferrer">
          {v}
        </a>
      );
    }
  } else if (children.type === "badge") {
    if (children.ui_variant) {
      const variant = children.ui_variant;
      v = (
        <span
          className={`d-inline-flex px-2 text-${variant}-emphasis bg-${variant}-subtle border border-${variant}-subtle rounded-2 align-self-start fs-13`}
        >
          {v}
        </span>
      );
    } else {
      v = (
        <span
          className="badge"
          style={{ backgroundColor: children.ui_background_color }}
        >
          {v}
        </span>
      );
    }
  } else if (children.type === "html") {
    v = <div dangerouslySetInnerHTML={{ __html: v }} />;
  } else if (children.type === "bool") {
    if (v) {
      v = <i className="bi-check-circle text-success" />;
    } else {
      v = <i className="bi-x-circle text-danger" />;
    }
  } else if (children.type === "class") {
    v = <children.class>{v}</children.class>;
  } else if (children.type === "phonenumber") {
    v = formatPhoneNumberWithAccountCountry(v);
    rowClassName = "text-nowrap";
  } else if (children.type === "datetime") {
    v = formatDate(v);
  } else if (children.type === "function") {
    v = v();
  } else if (children.type === "seconds") {
    v = formatSeconds(v);
  }
  return (
    <td key={children.value} className={rowClassName} style={children.style}>
      {v}
    </td>
  );
};

const DataTableRow = ({
  children,
  dataBsTarget,
  onClick,
  dataBsToggle = "offcanvas",
}) => {
  const navigate = useNavigate();

  return (
    <tr
      data-id={children.id}
      data-bs-toggle={dataBsTarget ? dataBsToggle : "offcanvas"}
      data-bs-target={dataBsTarget}
      onClick={() => {
        if (children.url) {
          navigate(children.url);
        }
        if (onClick) {
          onClick(children);
        }
      }}
    >
      {children.columns.map((c, idx) => {
        return <ColumnFormatter key={idx}>{c}</ColumnFormatter>;
      })}
    </tr>
  );
};

export default DataTableRow;
