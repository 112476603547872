import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { OffcanvasManager } from "./OffCanvas";
import { ModalManager } from "./Modal";

const uuidRegExp = ".*-.*";
const intRegExp = "\\d+";

export const useModalDisplayManager = (
  modalId,
  visiblePaths,
  isOffcanvas = false
) => {
  const ManagerClass = isOffcanvas ? OffcanvasManager : ModalManager;

  const location = useLocation();
  const [paths, setPaths] = useState([]);
  const [modalManager] = useState(new ManagerClass(modalId));

  useEffect(() => {
    const computedPaths = [];
    for (const i in visiblePaths) {
      const p = new RegExp(
        visiblePaths[i].replace("{uid}", uuidRegExp).replace("{int}", intRegExp)
      );
      computedPaths.push(p);
    }
    setPaths(computedPaths);
  }, []);

  useEffect(() => {
    const didMatch = paths.some((p) => {
      const match = !!window.location.pathname.match(p);
      if (match) return p;
      return null;
    });

    didMatch ? modalManager?.toggle() : modalManager?.hide();
  }, [location, paths]);
};
