import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";

import SimpleForm from "../../components/Form/SimpleForm";
import { SettingsFactory } from "../../components/Settings/SettingsFactory";
import { get, post } from "../../lib/api";
import Loader from "../../components/Loader";
import APIResponseError from "../../components/APIResponseError";
import FeatureContainer from "../../components/FeatureContainer";
import FeatureTitle from "../../components/FeatureContainer/FeatureTitle";
import FeatureBody from "../../components/FeatureContainer/FeatureBody";

const fields = [
  {
    label: "Message Body",
    body: "The message that will be sent.",
    as: "textarea",
    props: {
      mergeFields: [
        {
          name: "Shop",
          value: "{shop}",
        },
      ],
      label: "Message Body",
      name: "message",
      rows: 5,
      maxLength: 1000,
    },
  },
  {
    label: "Enabled",
    body: "Only sent if this is enabld.",
    as: "checkbox",
    props: {
      id: "enabled",
      name: "enabled",
      label: "Enabled",
    },
  },
];

const LocationAutomatedResponseForm = ({ locationId }) => {
  const [responseData, setResponseData] = useState(null);
  const [apiError, setApiError] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const apiSubmitHandler = (data) => {
    return post(`shop/${locationId}/autoresponse/`, data);
  };

  const apiGetHandler = async () => {
    setIsLoading(true);
    setApiError(null);
    try {
      const response = await get(`shop/${locationId}/autoresponse/`);
      setResponseData(response.data);
    } catch (error) {
      setApiError(error);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    apiGetHandler();
  }, [locationId]);

  if (isLoading) return <Loader />;
  if (apiError) return <APIResponseError response={apiError} />;

  return (
    <FeatureContainer>
      <FeatureTitle
        title="After Hours Response"
        description={
          <span>
            Automatically send a message when your business is closed based on
            your{" "}
            <Link to="/settings/location/store-hours" k>
              Store Hours
            </Link>
            .
          </span>
        }
      />
      <FeatureBody>
        <SimpleForm
          initialValues={responseData}
          apiSubmitHandler={apiSubmitHandler}
        >
          <SettingsFactory fields={fields} />
        </SimpleForm>
      </FeatureBody>
    </FeatureContainer>
  );
};

export default LocationAutomatedResponseForm;
