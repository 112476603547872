import React, { useEffect } from "react";
import VerticalNav, {
  Divider,
  NavLink,
  SectionTitle,
} from "../../components/VerticalNav";
import LocationSettingsFilter from "./LocationSettingsFilter";
import { settingsIndex } from "./settingsIndex";

const LocationDetailNav = () => {
  const [filteredSettings, setFilteredSettings] = React.useState(settingsIndex);

  useEffect(() => {}, [filteredSettings]);

  return (
    <div
      className="d-none d-sm-block border-end overflow-scroll"
      style={{ minWidth: "215px", height: "calc(100vh - 125px)" }}
    >
      <VerticalNav style={{ top: null }}>
        <div
          className="position-sticky top-0 bg-white"
          style={{ zIndex: 1000 }}
        >
          <h5 className="fw-bold mb-2">Location Settings</h5>
          <LocationSettingsFilter
            settingsIndex={settingsIndex}
            onChange={(updatedSettings) => {
              setFilteredSettings(updatedSettings);
            }}
          />
        </div>

        {Object.keys(filteredSettings || {}).map((settingSection) => {
          return (
            <>
              <SectionTitle>{settingSection}</SectionTitle>
              {Object.keys(filteredSettings[settingSection]).map(
                (settingKey) => (
                  <NavLink
                    to={filteredSettings[settingSection][settingKey].path}
                  >
                    {settingKey}
                  </NavLink>
                )
              )}
              <Divider />
            </>
          );
        })}
      </VerticalNav>
    </div>
  );
};

export default LocationDetailNav;
