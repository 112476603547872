import React from "react";
import { ListItem } from "../../FeatureUpgrade/SplitPanelFeatureUpgrade";
import UpgradeAccountButtonV2, {
  PricingPlans,
} from "../../components/UpgradeAccountButtonV2";

const WebchatStandardFeatures = () => {
  return (
    <div className="d-flex flex-column gap-3 lead fw-medium">
      <p className="h4">Upgrade to Standard</p>
      <div className="h-100 flex-column d-flex gap-3">
        <ListItem>Change the button type</ListItem>
        <ListItem>Change the accent color</ListItem>
        <ListItem>Change the button text color.</ListItem>
        <ListItem>Show a greeter message to help with interaction</ListItem>
      </div>
      <div className="align-self-center mt-3">
        <UpgradeAccountButtonV2
          featureName="Upgrade to Webchat Standard"
          plan={PricingPlans.standard}
          redirectPath="/settings/location/webchat-widget"
        />
      </div>
    </div>
  );
};

export default WebchatStandardFeatures;
