import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useActions, useState } from "../../../lib/overmind";
import LightspeedWorkorderForm from "./LightspeedWorkorderForm";
import Button, { ButtonVarint } from "../../Button";
import Modal, { hideModal } from "../../Modal";
import Loader from "../../Loader";

export const editWorkorderOffcanvasId = "editWorkorderModal";

const ActionRow = ({ workorderConfigId }) => {
  const actions = useActions().lightspeed;

  return (
    <div className="d-flex flex-row">
      <Button className="me-auto" form="lightspeedWorkorderForm">
        Save
      </Button>
      <div className="d-flex flex-row gap-3">
        <Button
          variant={ButtonVarint.secondaryOutline}
          onClick={() => {
            hideModal(editWorkorderOffcanvasId);
          }}
        >
          Cancel
        </Button>
        <Button
          className="ms-auto"
          variant={ButtonVarint.dangerOutline}
          onClick={() => {
            if (!confirm("Are you sure?")) return;
            actions.deleteWorkorderConfig(workorderConfigId);
            hideModal(editWorkorderOffcanvasId);
          }}
        >
          Delete
        </Button>
      </div>
    </div>
  );
};

const getId = (path) => {
  try {
    return path?.match(/\d+/)[0];
  } catch {
    return null;
  }
};

const LightspeedEditWorkorderAutomationModal = () => {
  const state = useState().lightspeed;
  const actions = useActions().lightspeed;
  const params = useParams();
  const [workorder, setWorkorder] = React.useState();
  const navigate = useNavigate();

  React.useEffect(() => {
    if (!state.configs) return;
    const foundWorkorder = actions.getWorkorder(getId(params["*"]));
    setWorkorder(foundWorkorder);
  }, [params]);

  React.useEffect(() => {
    if (!state.configs) return;
    const foundWorkorder = actions.getWorkorder(getId(params["*"]));
    setWorkorder(foundWorkorder);
  }, [state.configs]);

  return (
    <Modal
      id={editWorkorderOffcanvasId}
      title="Edit"
      onHide={() => {
        navigate(".", { replace: true });
      }}
    >
      <Loader hidden={!state.isLoading} />
      <div hidden={state.isLoading}>
        <LightspeedWorkorderForm
          workorder={workorder}
          onSuccess={() => {
            hideModal(editWorkorderOffcanvasId);
          }}
        >
          <ActionRow workorderConfigId={workorder?.id} />
        </LightspeedWorkorderForm>
      </div>
    </Modal>
  );
};

export default LightspeedEditWorkorderAutomationModal;
