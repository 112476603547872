import React from "react";
import { Link } from "react-router-dom";
import OvermindDataTable from "../components/DataTable/OvermindDataTable";
import PaymentReport from "./PaymentReport";
import FeatureContainer from "../components/FeatureContainer";
import FeatureTitle from "../components/FeatureContainer/FeatureTitle";
import CreateButton from "../components/CreateButton";

const PaymentTable = () => {
  return (
    <FeatureContainer>
      <FeatureTitle
        title="Payments"
        description="Monitor text payment activity, review past transactions, and send new requests."
      >
        <CreateButton as="link" to="create">
          New Payment Request
        </CreateButton>
      </FeatureTitle>
      <div className="d-flex flex-column gap-3">
        <PaymentReport />
        <OvermindDataTable overmindPropertyKey="payments" />
      </div>
    </FeatureContainer>
  );
};

export default PaymentTable;
