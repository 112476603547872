import React from "react";
import FeatureTitle from "../../FeatureContainer/FeatureTitle";
import { FeatureTierBadge } from "../../FeatureTierBadge";
import CreateButton from "../../CreateButton";
import { createWorkorderOffcanvasId } from "./LightspeedCreateWorkorderAutomationModal";

const LightspeedWorkorderAutomationTitle = ({ isLoading }) => {
  return (
    <FeatureTitle
      title={
        <div className="text-capitalize">
          <span>Workorder Automation</span>
          <FeatureTierBadge className="ms-3 " />
        </div>
      }
      description="Automatically send text messages based on the status of a Workorder."
    >
      <CreateButton
        data-bs-target={`#${createWorkorderOffcanvasId}`}
        data-bs-toggle="modal"
        disabled={isLoading}
      >
        New Status Message
      </CreateButton>
    </FeatureTitle>
  );
};

export default LightspeedWorkorderAutomationTitle;
