import React, { useEffect, useState } from "react";
import * as api from "../../../lib/api";
import { useActions, useState as useStateO } from "../../../lib/overmind";
import Checkbox from "../../Form/Checkbox";
import LabeledField from "../../Form/LabeledField";
import SimpleForm from "../../Form/SimpleForm";
import MergeFieldsTextArea from "../../Form/MergeFieldsTextArea";
import { CommonMergeFields, MergeField } from "../../Form/MergeFields";

const mergeFields = [
  CommonMergeFields.firstName,
  CommonMergeFields.lastName,
  new MergeField("Workorder ID", "{workorder id}", "The ID of the work order"),
  new MergeField("Time In", "{time in}", "The Time In field of the work order"),
  new MergeField("ETA Out", "{eta out}", "The ETA Out field of the work order"),
  new MergeField("Total", "{total}", "The total of the work order."),
  new MergeField(
    "Description",
    "{description}",
    "The description of the work order"
  ),
  new MergeField(
    (
      <span>
        <i className="bi-link-45deg" />
        Payment Link
      </span>
    ),
    "{payment link}",
    "Insert a payment link with the total of the Workorder"
  ),
];

const LightspeedWorkorderForm = ({ workorder, children, onSuccess }) => {
  const [internalWorkorder, setInternalWorkorder] = useState(workorder);
  const state = useStateO().lightspeed;
  const actions = useActions().lightspeed;

  const initialValues = {
    min_delay: 0,
    enabled: true,
    body: "",
    status: "",
  };

  useEffect(() => {
    setInternalWorkorder(workorder);
  }, [workorder]);

  const onSubmit = async (values) => {
    let resp;
    if (internalWorkorder) {
      resp = await api.put(
        `/shop/{exid}/lightspeed/workorder-status/${internalWorkorder.id}/`,
        values
      );
    } else {
      resp = await api.post(
        "/shop/{exid}/lightspeed/workorder-status/",
        values
      );
    }
    actions.updateOrAddWorkorderConfig(resp.data);
    onSuccess && onSuccess();
    return resp;
  };

  return (
    <>
      <SimpleForm
        id="lightspeedWorkorderForm"
        resetForm
        initialValues={internalWorkorder || initialValues}
        apiSubmitHandler={onSubmit}
        hideSubmitButton
      >
        <MergeFieldsTextArea
          label="Message Body"
          id="bodyField"
          name="body"
          as="textarea"
          required
          rows={4}
          maxLength={1000}
          mergeFields={mergeFields}
        />
        <LabeledField
          label="Lightspeed Status"
          required
          name="status"
          id="status"
          as="select"
        >
          <option value=""> </option>
          {state.workorderStatusData?.map(({ name, id }) => {
            return (
              <option
                key={id}
                value={id}
                disabled={state.activeWorkorderStatuses?.includes(id)}
              >
                {name}
              </option>
            );
          })}
        </LabeledField>
        <LabeledField
          label="Minimum Delay"
          id="min_delay"
          name="min_delay"
          as="select"
        >
          <option value="0">ASAP</option>
          <option value="15">15 minutes</option>
          <option value="60">1 hour</option>
          <option value="120">2 hours</option>
          <option value="240">4 hours</option>
          <option value="480">8 hours</option>
          <option value="1440">1 day</option>
          <option value="4320">3 days</option>
          <option value="7200">5 days</option>
          <option value="10080">7 days</option>
        </LabeledField>
        <Checkbox id="enabled" label="Active" />
      </SimpleForm>
      {children}
    </>
  );
};

export default LightspeedWorkorderForm;
