import React from "react";
import { Link } from "react-router-dom";

const SectionNavBar = ({ children }) => {
  const [activePath, setActivePath] = React.useState("");

  return (
    <nav
      className="nav border-bottom position-sticky top-0 bg-white"
      style={{ fontSize: "15x", fontWeight: "600", zIndex: 5 }}
    >
      {children?.map((item, idx) => {
        if (item.hidden) return null;
        if (item.type === "external") {
          return (
            <a
              target="_blank"
              className="nav-link text-secondary"
              key={idx}
              href={item.path}
              rel="noreferrer"
              style={item.style}
            >
              {item.title}
            </a>
          );
        }
        if (item.title) {
          const padding = idx === 0 ? "ps-0" : "";

          if (!activePath && window.location.pathname.includes(item.path)) {
            setActivePath(item.path);
          }
          const isActive = activePath === item.path;
          return (
            <Link
              key={idx}
              onClick={() => {
                setActivePath(item.path);
              }}
              className={`nav-link ${padding} ${
                isActive ? "text-decoration-underline" : "text-secondary"
              }`}
              to={item.path}
            >
              {item.title}
            </Link>
          );
        }
        return null;
      })}
    </nav>
  );
};

export default SectionNavBar;
