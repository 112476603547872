import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import SimpleForm from "../../components/Form/SimpleForm";
import { get, put } from "../../lib/api";
import TimeControlRow from "./TimeControlRow";
import TemporarilyClosedForBusiness from "./TemporarilyClosedForBusinessChecbox";
import Loader from "../../components/Loader";
import APIResponseError from "../../components/APIResponseError";
import FeatureContainer from "../../components/FeatureContainer";
import FeatureTitle from "../../components/FeatureContainer/FeatureTitle";

const dowIndex = {
  MON: "Monday",
  TUE: "Tuesday",
  WED: "Wednesday",
  THU: "Thursday",
  FRI: "Friday",
  SAT: "Saturday",
  SUN: "Sunday",
};

const daysOfWeek = Object.keys(dowIndex);

const LocationHouseForm = ({ locationId }) => {
  const [responseData, setResponseData] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [apiError, setApiError] = useState(null);
  const [hasError, setHasError] = useState(false);
  const [hoursDiabled, setHoursDisabled] = useState(false);

  const apiGetHandler = async () => {
    setIsLoading(true);
    setApiError(false);
    try {
      const response = await get(`/shop/${locationId}/hours/`);
      const formData = {
        closed_for_business: response.data.closed_for_business,
      };
      if (response?.data?.hours) {
        for (const item of response.data.hours) {
          formData[item.day_of_week] = item;
        }
      }

      setHoursDisabled(formData.closed_for_business);
      setResponseData(formData);
    } catch (error) {
      setApiError(error);
    }

    setIsLoading(false);
  };

  useEffect(() => {
    apiGetHandler();
  }, [locationId]);

  const apiSubmitHandler = async (data) => {
    if (hasError) {
      alert("Please fix errors before submitting.");
      return;
    }
    const payload = {
      closed_for_business: true,
      hours: [],
    };

    for (const key of Object.keys(data)) {
      if (key === "closed_for_business") {
        payload.closed_for_business = data[key];
        continue;
      }
      if (!data[key] || !data[key].open_time) continue;
      payload.hours.push(data[key]);
    }

    await put(`/shop/${locationId}/hours/full_update/`, payload);
  };

  if (isLoading) {
    return <Loader />;
  }

  if (apiError) {
    return <APIResponseError response={apiError} />;
  }

  return (
    <FeatureContainer>
      <FeatureTitle
        title="Location Hours"
        description={
          <span>
            This affects your automations and{" "}
            <Link to="/settings/location/after-hours-response">
              After Hours Response
            </Link>
          </span>
        }
      />
      <div className="feature-container">
        <SimpleForm
          initialValues={responseData}
          apiSubmitHandler={apiSubmitHandler}
        >
          <div className="d-flex flex-column gap-4">
            <fieldset disabled={hoursDiabled}>
              <div id="location-hours" className="d-flex gap-2 flex-column">
                {daysOfWeek.map((dow) => (
                  <TimeControlRow
                    key={dow}
                    dow={dow}
                    label={dowIndex[dow]}
                    onHasError={(e) => setHasError(e)}
                  />
                ))}
              </div>
            </fieldset>
            <TemporarilyClosedForBusiness
              onChange={(isEnabled) => setHoursDisabled(isEnabled)}
            />
          </div>
        </SimpleForm>
      </div>
    </FeatureContainer>
  );
};

export default LocationHouseForm;
