import { useField, useFormikContext } from "formik";
import React, { useRef } from "react";
import MergeFields from "./MergeFields";
import StandardFormGroup from "./StandardFormGroup";

/*
  mergeFields: [
    {
      name: "First Name",
      value: "{first name}"
    },
    CommonMergeFields.lastName
  ]
*/

const MergeFieldsTextArea = (props) => {
  const { values, setFieldValue } = useFormikContext();
  const [field, meta] = useField(props);
  const ref = useRef();

  const onMergeFieldClick = (v) => {
    let newFieldValue = "";
    if (values[props.name]) {
      const currentValue = values[props.name];
      const cursorPosition = ref.current?.selectionStart;
      const formattedValue = cursorPosition === 0 ? `${v} ` : ` ${v}`;
      newFieldValue =
        currentValue.slice(0, cursorPosition) +
        formattedValue +
        currentValue.slice(cursorPosition);
    } else {
      newFieldValue = `${v}`;
    }
    setFieldValue(props.name, newFieldValue);
    ref.current.focus();
  };

  return (
    <StandardFormGroup label={props.label} fieldId={props.id} className="mb-3">
      <textarea
        ref={ref}
        className="form-control mb-2"
        {...props}
        {...field}
        onChange={(e) => {
          props?.onChange && props.onChange(e);
          field.onChange(e);
        }}
      />
      <MergeFields onClick={onMergeFieldClick}>{props.mergeFields}</MergeFields>
      {!!meta.touched && !!meta.error && <div>{meta.error}</div>}
    </StandardFormGroup>
  );
};

export default MergeFieldsTextArea;
