import React from "react";
import FeatureContainer from "../components/FeatureContainer";
import FeatureTitle from "../components/FeatureContainer/FeatureTitle";
import FeatureBody from "../components/FeatureContainer/FeatureBody";
import BrowserExtensionDownload from "./BrowserExtensionDownload";

const BrowserExtensionSettings = () => {
  return (
    <FeatureContainer>
      <FeatureTitle
        title="Browser Extension"
        description="Enhance your Point of Sale experience."
      />
      <FeatureBody>
        <p>Downloading the Browser Extension enhances your Point of Sale</p>
        <ul className="mb-3">
          <li>View and reply to messages without leaving your Point of Sale</li>
          <li>Send messages for special orders</li>
          <li>Text receipts and work order quotes</li>
          <li>Plus more</li>
        </ul>
        <p className="lead">
          To get started click the download button below{" "}
          <i className="bi-arrow-down" />
        </p>
        <BrowserExtensionDownload />
      </FeatureBody>
    </FeatureContainer>
  );
};

export default BrowserExtensionSettings;
