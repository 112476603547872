import React from "react";
import { createWorkorderOffcanvasId } from "./LightspeedCreateWorkorderAutomationModal";

const LightspeedWorkorderZeroState = () => (
  <div className="text-center my-5 text-secondary">
    <h5>No automations yet</h5>
    <p>
      <a
        role="button"
        className="text-underline"
        data-bs-target={`#${createWorkorderOffcanvasId}`}
        data-bs-toggle="modal"
      >
        Create your first status configuration
      </a>
    </p>
  </div>
);

export default LightspeedWorkorderZeroState;
